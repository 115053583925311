import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../_services/token-storage.service';

const API_URL = 'https://api.arcreances.com/agents/';
//const API_URL = 'http://127.0.0.1:8000/agents/';
const info_dette = 'https://api.arcreances.com/dettes/';
const info_recus = 'https://api.arcreances.com/recus/';
const rappDette_info = 'https://api.arcreances.com/rappdette/'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private tokenStore: TokenStorageService) { }


  
  //recup dettes debiteurs
  getDebs(agent_id: number){
    return this.http.get(API_URL + agent_id);
  }

  getInfoDette(id:number){
    return this.http.get(info_dette + id);
  }

  getInfoRecu(id:number){
    return this.http.get(info_recus + id);
  }

  getRappDette(id:number){
    return this.http.get(rappDette_info + id);
  }


}
