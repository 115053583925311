<div class="container" *ngIf="!isLoggedIn">
	<header class="jumbotron">
		<h2>AGENT DE RECOUVREMENT</h2>
	</header>
</div>

<div class="container" *ngIf="isLoggedIn">
	<header class="jumbotron">
		<h2>Bienvenu {{agent.nom}}</h2>
	</header>
</div>
